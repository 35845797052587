<template>
  <div id="select-company-starting-point">
    <div class="mb-3">
      <h1 class="header-text">
        What stage is your new business at?
      </h1>
    </div>
    <stage-cards class="cards" :cards="cards" />
  </div>
</template>

<script>
  import BusinessIdea from '@images/illustrations/products/idea.png'
  import FormTheCompany from '@images/illustrations/products/document.png'
  export default {
    name: 'SelectCompanyStartingPoint',
    components: {
      StageCards:     () => import('./StageCards.vue'),
    },
    data() {
      return {
        cards: [
          {
            image: BusinessIdea,
            title: 'Just an Idea',
            description: 'Not sure what to consider before going from concept to business? We have a few suggestions.',
            stage: 'business_idea_and_plan',
          },
          {
            image: FormTheCompany,
            title: "I'm ready to file",
            description: 'File the necessary paperwork with the state to create a legal business entity.',
            stage: 'form_the_company',
          },
        ],
      }
    },
  }
</script>

<style lang="scss" scoped>
  #select-company-starting-point {

    .cards{
      display: flex;
      justify-content: center;
    }

    .header-text {
      font-size: 2.25rem;
      font-weight: 700;
      letter-spacing: -0.02em;
      text-align: center;
      margin-bottom: 3rem;
    }
  }
</style>
